import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout, Slider } from '../components'
import { media } from '../styles/utils'
import { container, padding, colours, bgImage, wrapper, type } from '../styles/global'
import { parseACF } from '../utils'

const Index = (props) => {
	const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
			
	const renderSlider = () => {
		if (!data.carousel_display || !data.carousel_items) return;

		const slides = data.carousel_items.map((item) => {
			if (data.carousel_static_caption_display) item.use_caption = false;
			return item
		})

		return (
			<SliderWrapper>
				<Slider
					slides={data.carousel_items}
					sliderStyles={sliderStyles}
					hoverCursors
				/>
				
				{data.carousel_static_caption_display && (
					<Subheading
						dangerouslySetInnerHTML={{__html: data.carousel_static_caption}}
					/>
				)}
			</SliderWrapper>
		)
	}	

	const renderText = () => {
		if (!data.introduction) return
		
		return (
			<Intro>
				<Text
					dangerouslySetInnerHTML={{__html: data.introduction}}
				/>
			</Intro>
		)
	}

	return (
		<Layout
			meta={data.seo}
			background={colours.red}
			isHome={true}
        >
			<Container>
				{renderSlider()}
				{renderText()}
			</Container>

			<FooterSpacer/>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
    align-items: center;
	height: 100%;
	min-height: 100vh;

	padding-top: 4em;
	padding-bottom: 40px;
`

// Slider

const SliderWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: flex-start;
	position: relative;
	width: 100%;
	max-width: 40vw;

	${media.phone`
		max-width: 100%;
	`}

	${Subheading} {
		margin-top: 16px;
		max-width: 85%;

		${media.phone`
			width: 100%;
			margin-top: 8px;
		`}

		&, p {
			${type.caption}
		}
	}
`

const sliderStyles = css`
	overflow: visible;
	height: 25vw;

	${media.phone`
		height: 256px;
	`}
	
	.slide {
		background-color: ${colours.red};
	}

	.image-wrapper {
		.caption {
			max-width: 85%;
			position: absolute;
			bottom: -16px;
			transform: translateY(100%);

			${media.phone`
				width: 100%;
			`}
		}

		img {	
			width: auto;
			height: 100%;
			min-height: 25vw;
			object-fit: contain;
			object-position: left;

			${media.phone`
				height: 256px;
			`}
		}
	}
`

// Intro

const Intro = styled.div`
	margin-top: auto;

	p:last-child {
		margin-bottom: 0;
	}

	${media.phone`
		display: none;
	`}
`

// Footer Spacer

const FooterSpacer = styled.div`
	height: 155px;
	background-color: ${colours.red};

	${media.phone`
		display: none;
	`}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                title
                acf_json
            }
        }
    }
`


export default Index